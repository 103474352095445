<template>
  <v-navigation-drawer
    app
    clipped
    fixed
    :permanent="$vuetify.breakpoint.mdAndUp"
    v-bind="$attrs"
    v-on="$listeners"
    id="left-sidebar"
    width="325"
  >
    <v-list>
      <template v-for="(item, index) in menuItems">
        <v-list-group
          sub-group
          :key="item.title + index"
          :value="isActive(item.prefixes, $route.name)"
          v-if="item.children"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-4">
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="defaultActionForParentMenu(item)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template slot="appendIcon">
            <v-icon>mdi-chevron-down</v-icon>
          </template>
          <v-list-item
            v-for="(nestedItem, nestedIndex) in item.children"
            :key="nestedItem.title + nestedIndex"
            :to="{
              name: nestedItem.routeName,
              params: nestedItem.routeParams,
            }"
          >
            <v-list-item-icon class="sub-item-icon">
              <v-icon v-text="nestedItem.icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="nestedItem.title" />
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item
          :key="item.title + index + '1'"
          :to="{ name: item.routeName, params: item.routeParams }"
          color="primary"
          :exact="item.exact"
          @click="defaultActionForParentMenu(item)"
          v-else
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </template>
      <!-- <v-list-item color="primary" @click="toggleDarkMode">
        <v-list-item-icon>
          <v-icon>mdi-theme-light-dark</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ theme === "light" ? "Dark" : "Light" }} Mode </v-list-item-title>
        </v-list-item-content>
      </v-list-item> -->
      <v-list-item color="primary" style="height: 20vh"> </v-list-item>
    </v-list>

    <div class="body-2 version-text mb-2">
      <v-divider class="mb-2" />
      Version: {{ webVersion }}
    </div>
  </v-navigation-drawer>
</template>

<script>
import { sidebarItems } from "@/constants/sidebarItems";
import { mapGetters } from "vuex";

export default {
  computed: {
    menuItems() {
      return sidebarItems.map(this.filterMenuItem).filter((x) => !!x);
    },
    webVersion() {
      return window.WEB_VERSION;
    },
    ...mapGetters({
      theme: "dashboard/getTheme",
    }),
  },
  mounted() {
    if (process.browser) {
      const savedTheme = window.localStorage.getItem("DarkMode");
      this.$store.commit("dashboard/setTheme", savedTheme ? savedTheme : "dark");
      this.$vuetify.theme.dark = savedTheme === "dark";
    }
  },
  methods: {
    isActive(rootPrefixes, currentRouteName) {
      if (!rootPrefixes || !Array.isArray(rootPrefixes)) {
        return false;
      }
      let active = false;
      rootPrefixes.forEach((el) => {
        if (currentRouteName.indexOf(el) !== -1) {
          active = true;
        }
      });
      return active;
    },
    defaultActionForParentMenu(sidebarItem) {
      if (sidebarItem.defaultAction) {
        sidebarItem.defaultAction(this);
      }
    },
    filterMenuItem(item) {
      const menu = { ...item };
      if (menu.children) {
        menu.children = menu.children.map(this.filterMenuItem).filter((x) => !!x);
      }
      if (item.showWhen && !eval(item.showWhen)) {
        return null;
      }
      if (!menu.routeName && !(menu.children && menu.children.length)) {
        return null;
      }
      if (menu.requirePrivilege && menu.requirePrivilege.path) {
        if (!this.verifyPrivilege(menu.requirePrivilege.path, menu.requirePrivilege.method)) {
          return null;
        }
      }
      if (!(menu.children && menu.children.length)) {
        menu.children = null;
      }
      return menu;
    },
    toggleDarkMode() {
      if (process.browser) {
        if (this.theme === "dark") {
          this.$vuetify.theme.dark = false;
          window.localStorage.setItem("DarkMode", "light");
          this.$store.commit("dashboard/setTheme", "light");
        } else {
          this.$vuetify.theme.dark = true;
          window.localStorage.setItem("DarkMode", "dark");
          this.$store.commit("dashboard/setTheme", "dark");
        }
      }
    },
  },
};
</script>

<style lang="scss">
#left-sidebar {
  .v-list {
    height: calc(100% - 40px);
    overflow: auto;

    :not(.v-list-group--sub-group) {
      a.v-list-item {
        padding: 0 16px !important;
        margin: 0 !important;
      }
    }
    .v-list-group--sub-group {
      a.v-list-item {
        padding: 0 16px !important;
        margin-left: 16px !important;
      }
    }
    .v-list-item {
      padding: 0 16px !important;
      margin: 0 !important;
    }
    .v-list-item__title {
      font-family: "Roboto Slab", "Roboto", sans-serif;
      // font-weight: 500;
    }
    .v-list-item__icon:first-child {
      margin-right: 16px;
    }
    .v-navigation-drawer__content {
      overflow-x: auto !important;
    }
    .sub-item-icon {
      display: flex !important;
    }
    .v-list-group__header__prepend-icon {
      display: none;
    }
    .v-list-group--sub-group {
      width: 100%;
    }
    .v-list-group__header {
      padding-left: 0 16px !important;
      margin: 0;
    }
  }

  .version-text {
    width: 100%;
    background: white;
    text-align: center;
    color: teal;
  }
}
</style>
